/* eslint-disable max-len */
import enUS from '../en-us';

const deDE = {
  common: {
    ctas: {
      learnMore: 'Mehr erfahren',
      learnMoreAbout: 'Lerne mehr über',
      viewDetails: 'Details anzeigen',
      viewDetailsAbout: 'Details zu anzeigen',
      viewRecipe: 'Rezept anzeigen',
      readMore: 'Weiterlesen',
    },
    faq: {
      faqs: 'Häufig gestellte Fragen',
    },
    navigation: {
      whereToBuy: 'Wo zu kaufen',
      selectYourRegion: 'Wählen Sie Ihre Region',
    },
    rate: {
      starLabel: '${1} Star',
      starsLabel: '${1} Sterne',
    },
    storeLocator: {
      tabbedHeader: {
        inStoreText: 'Auf Lager',
        buyOnlineText: 'Online kaufen',
      },
      inputLocation: {
        label: 'Ort eingeben',
        submit: 'Suche senden',
      },
      mapControls: {
        curbsideLabel: 'Nur Abholung am Straßenrand',
      },
      map: {
        title: 'Orte, an denen ${1} Rezepte verfügbar sind',
      },
      infoList: {
        curbsidePickup: 'Abholung am Straßenrand möglich',
        directions: 'Richtungen',
        website: 'Website',
        buyOnline: 'Online kaufen',
        facebook: 'Facebook',
      },
    },
    redirectModal: {
      headline: 'Besuchen sie aus ${1}?',
      subheadline: 'Besuchen Sie Ihre regionale Website für weitere relevante Informationen',
      redirectCta: 'Bring mich dahin',
      stayCta: 'Bleib hier',
    },
  },
  product: {
    hero: {
      review: '1 Rezension',
      reviews: '${1} Bewertungen',
      askAQuestion: 'Stelle eine Frage',
      whereToBuy: 'Wo zu kaufen',
    },
    tabs: {
      whatsInside: 'Was ist da drin',
      nutritionalInformation: 'Nährwert-Information',
    },
    table: {
      composition: 'Komposition',
      additives: 'Zusatzstoffe pro kg',
      calorieContent: 'Kaloriengehalt',
      recommendedFor: 'Empfohlen für',
      ingredients: 'Zutaten',
      analyticalConstituents: 'Analytische Bestandteile',
      amount: 'Menge',
      guaranteedAnalysis: 'Garantierte Analyse',
      viewMore: 'Alle ansehen',
      viewLess: 'Weniger anzeigen',
      downloadNutrientProfile: 'Nährstoffprofil herunterladen',
    },
    review: {
      sectionTitle: 'Was andere Haustiereltern sagen',
      writeAReview: 'Eine Rezension schreiben',
      productStandouts: 'Produktmerkmale',
      noReviewTitle: 'Wir möchten hören, was Ihr Haustier denkt!',
      noReviewSubtitle: 'Derzeit gibt es keine Bewertungen für dieses Produkt.',
      readMore: 'Weiterlesen',
      readMoreFrom: 'Lesen Sie mehr von ${1}',
      lessInformation: 'Weniger Informationen',
      portraitRespondent: 'Porträt des Befragten',
      previousPage: 'Vorherige Seite',
      nextPage: 'Nächste Seite',
      pageNumber: 'Seite ${1}',
      standouts: {
        pickyEaterApproved: 'Pingeliger Esser genehmigt',
        improvedHealth: 'Verbesserte Gesundheit',
        easyDigestion: 'Einfache Verdauung',
        skinCoatResults: 'Haut / Fell Ergebnisse',
        happyPetParent: 'Glückliches Haustier Elternteil',
        increasedEnergy: 'Erhöhte Energie',
      },
    },
    feedingGuide: {
      eyebrow: 'Fütterungsanleitung',
      dogTitle: 'Finden Sie die richtige Menge für Ihren Hund',
      catTitle: 'Finden Sie die richtige Menge für Ihre Katze',
      moreInfo: 'Genaue Information',
      bodyChartCTALabel: 'Score-Chart-Körper',
    },
  },
  categoryLanding: {
    filters: {
      filterApplied: '${1} Filter angewendet',
      filtersApplied: '${1} Filter angewendet',
      result: '${1} Ergebnis',
      results: '${1} Ergebnisse',
      filters: 'Filter',
      filterRecipes: 'Rezepte filtern',
      noFilters: 'Keine Filter ausgewählt',
      numFiltersApplied: '(${1}) Filter anwenden',
      numFilterApplied: '1 Filter anwenden',
      clear: 'Klar',
      openFilters: 'Filter öffnen',
      closeFilters: 'Filter schließen',
      clearFilters: 'Filter löschen',
      appliedFilters:
        'Es sind ${1} Filter angewendet. Drücken Sie die Eingabetaste, um die Filter zu schließen',
      noFiltersApplied: '0 Filter angewendet',
      filterCategories: {
        kibbleSize: 'Kibble Größe',
        breedSize: 'Rassengröße',
        lifeStage: 'Lebensabschnitt',
        foodType: 'Lebensmitteltyp',
        solution: 'Lösung',
        recipe: 'Rezept',
        protein: 'Protein',
        supports: 'Unterstützt',
      },
    },
  },
  blogPost: {
    authors: {
      authors: 'Autoren',
      author: 'Autor',
    },
  },
  countries: {
    us: 'Vereinigte Staaten',
    de: 'Deutschland',
    ca: 'Kanada',
    co: 'Kolumbien',
  },
};

export default Object.assign({}, enUS, deDE);
