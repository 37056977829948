import { countryLocaleMap, externalFontMap, languageNames } from '../siteConfig';
import { getLocalizationProps } from './LanguageProvider';
import safeStringify from 'safe-json-stringify';

/**
 * Regular expresion to validate that an email has the form
 * anystring@anystring.anystring
 */
export const emailRegEx = /\S+@\S+\.\S+/;

/**
 * function to handle an async for each
 * @param {Array} [array] array to be traversed
 * @param {Function} [callback] function handler
 */
export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

/**
 * function to create an external address structure based on a URL
 * @param {string} [externalUrl] url to be added to the structure
 * @returns {object} external link object to be used with AccessibleButton or GenericLink component
 */
export function getExternalLinkFromString(externalUrl) {
  return {
    fields: {
      linkAddress: externalUrl,
    },
  };
}

/**
 * function to create an internal address structure based on a URL
 * @param {string} [internalUrl] url to be added to the structure
 * @returns {object} internal link object to be used with GenericLink component
 */
export function getInternalLinkFromString(internalUrl) {
  return {
    fields: {
      slug: internalUrl,
    },
  };
}

/**
 * function to create an internal page address structure based on a URL
 * @param {string} [internalUrl] url to be added to the structure
 * the [locale] page
 * @returns {object} internal page object to be used with AccessibleButton component
 */
export function getInternalPageFromString(internalUrl) {
  return {
    fields: {
      internalPage: getInternalLinkFromString(internalUrl),
    },
  };
}

/**
 * function to retrieve value from inner children in an object
 * @param {string} [key] key from object to be found
 * @param {object} [object] Object to look into
 * @returns {any} value found from object or undefined if not found
 */
export function deepFind(key, object) {
  if (!key) {
    return object;
  }
  return key.split('.').reduce((currentChild, key) => {
    if (currentChild && typeof currentChild[key] === 'undefined') {
      return key;
    }
    currentChild = currentChild[key];
    return currentChild;
  }, object);
}

/**
 * this function will add the dots after certain character limit to the given string, it also
 * splits the string to be able to hide a portion of it
 * @param {string} [string] string to be split
 * @param {number} [charLimit] char limit to add the dots
 * @returns {string} split string
 */
export function addExpandableFormat(string, charLimit = 116) {
  if (string?.length > charLimit) {
    return `${string.substring(
      0,
      charLimit,
    )}<span class="dots">...</span><span class="expanded">${string.substring(charLimit)}</span>`;
  } else {
    return string;
  }
}

/**
 * this function will round the given number to it's closest quarter
 * @param {number} [number] number to be rounded
 * @returns {string} rounded number
 */
export function roundClosestQuarter(number) {
  return (Math.floor(number * 4) / 4).toFixed(2);
}

/**
 * Creates an object composed of the picked object properties.
 * Similar to https://lodash.com/docs/#pick
 * @param {object} object original object to transform
 * @param {Array} paths allowlist of properties to include in the new object
 * @returns {object} new object with only the specified paths
 */
export function pick(object, paths) {
  // Takes advantage of the replacer param in JSON.stringify
  // eslint-disable-next-line max-len
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#The_replacer_parameter
  return JSON.parse(JSON.stringify(object, paths));
}

/**
 * Creates a deep copy of an object omitting a list of properties.
 * @param {object} object original object to transform
 * @param {Array} paths disallowlist of properties to omit in the new object
 * @returns {object} new object without the specified paths
 */
export function omit(object, paths) {
  return JSON.parse(
    JSON.stringify(object, (key, value) => {
      // If the key is in the disallow paths we skip the property by returning undefined
      // eslint-disable-next-line max-len
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#The_replacer_parameter
      return paths.includes(key) ? undefined : value;
    }),
  );
}

/**
 * Creates a deep copy of the contentful object without unused meta data properties
 * @param {object} object original object to transform
 * @param {Array} paths disallowlist of properties to omit in the new object
 * @returns {object} new object without the specified paths
 */
export function cleanContentfulData(
  object,
  paths = ['createdAt', 'environment', 'linkType', 'revision', 'space'],
) {
  return omit(object, paths);
}

/**
 * Function to transform object keys from snake_case to camelCase
 * @param {object} [object] object to be transformed
 * @returns {object} transformed object
 */
export function transformObjectKeysFromSnakeCaseToCamelCase(object) {
  const camelCaseReplacer = (match) => {
    return match.substring(1).toUpperCase();
  };
  const jsonString = JSON.stringify(object);
  return JSON.parse(jsonString.replace(/_(\w)/g, camelCaseReplacer));
}

/**
 * Takes an arbitrary string and converts it to a camel case variable name
 * @param {string} str Plain text string to convert
 * @returns {string} Converted camel case string
 */
export function toCamelCase(str) {
  if (!str) return str;
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) =>
      idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase(),
    )
    .replace(/\s+/g, '')
    .replace(/[^a-zA-Z]/gi, '');
}

/**
 * Format a value in metres to either kilometers or miles
 * @param {*} distance the distance in metres
 * @param {*} units whether to use kilometers or miles
 * @returns {string} whole number value rounded down
 */
export function formatDistance(distance, units) {
  return units === 'kilometers'
    ? `${(distance / 1000).toFixed(1)}`
    : `${(distance / 1000 / 1.6).toFixed(1)}`;
}

/**
 * Takes a string and capitilizes the first letter;
 * @param {string} str Plain text string to capitalize
 * @returns {string} same text with the first letter capitalized
 */
export function capitalize(str) {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Returns a form data object with the key pairs
 * @param {object} [data] object to encode in the form key=value
 * @returns {object} Encoded string ready to be sent trough the network
 */
export function encodeAsFormData(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

/**
 * Takes the page data and returns available locales
 * @param {Array} data the page data
 * @returns {Array} array of available locales
 */
export function getAvailableLocales(data) {
  return data?.length && data[0]?.fields?.availableLocales?.length
    ? data[0].fields.availableLocales.map((locale) => locale.toLowerCase())
    : ['en-us'];
}

/**
 * Function to check if a given locale is excluded based on locale environment variables
 * @param {string}  locale the locale code
 * @returns {boolean|undefined} whether or not the locale is permitted.
 */
export function isLocalePermitted(locale) {
  const includeLocales = process.env.INCLUDED_LOCALES;
  const excludeLocales = process.env.EXCLUDED_LOCALES;

  if (includeLocales && excludeLocales) {
    throw Error(`Only one of INCLUDED_LOCALES and EXCLUDED_LOCALES can be set`);
  } else if (includeLocales) {
    return includeLocales.indexOf(locale) != -1;
  } else if (excludeLocales) {
    return excludeLocales.indexOf(locale) == -1;
  }
}

/**
 * @param {string} [countryCode] country code to look for translation
 * @param {string} [locale] locale code
 * @returns {string} country name from locale code
 */
export function getCountryTranslationFromCountryCode(countryCode, locale) {
  const countriesTranslations = getLocalizationProps(locale, 'countries');
  let translation = countriesTranslations.translations[countryCode];
  if (!translation) translation = countriesTranslations.translations['undef'];
  return translation;
}

/**
 * Function to look up associated locales for a country
 * @param {string} countryCode the two letter ISO code representing the country
 * @returns {Array} A list of locales associated with the country code
 */
export function getLocalesFromCountryCode(countryCode) {
  return countryLocaleMap[countryCode] ? countryLocaleMap[countryCode] : [];
}

/**
 * @returns {string} country code where the user is located
 */
export async function getUserCountry() {
  const apiCountryRes = await fetch('/api/country');
  return await apiCountryRes.text();
}

/**
 * converts locale to Contentful format
 * @param {string} localeCode locale code to convert
 * @returns {string} formatted code
 */
export function upperCaseLocale(localeCode) {
  const formattedCode =
    localeCode?.indexOf('-') > -1
      ? `${localeCode.slice(0, 3)}${localeCode.slice(3, 5).toUpperCase()}`
      : localeCode;
  return formattedCode;
}

/**
 * Cleans locale data for site use
 * @param {Array} locales collection of locales
 * @returns {Array} formatted locales
 */
export function formatLocales(locales) {
  const localesData = JSON.parse(safeStringify(locales));

  localesData.items = localesData.items.map((locale) => {
    locale.code = locale.code.toLowerCase();
    if (languageNames[locale.code]) locale.name = languageNames[locale.code];
    return locale;
  });

  return localesData;
}

/**
 * Gets the canonical URL based on which locale is active and has precedence for a given page
 * @param {object} localeMap map of locales and urls
 * @param {string} locale locale code for the given locale
 * @returns {string} canonical URL if available
 */
export function getCanonical(localeMap, locale) {
  locale = locale.toLowerCase();
  if (localeMap?.[locale]) {
    return `${locale}/${localeMap[locale]}`;
  }
  if (locale) {
    return `${locale}`;
  }
  return null;
}

/**
 * Gets the secondary domains defined in SECONDARY_DOMAINS environmental variable
 * @returns {object|undefined} locale as property name and the domain as value.
 */
export function getSecondaryDomains() {
  const NEXT_PUBLIC_SECONDARY_DOMAINS = process.env.NEXT_PUBLIC_SECONDARY_DOMAINS;

  if (!NEXT_PUBLIC_SECONDARY_DOMAINS) return;

  return NEXT_PUBLIC_SECONDARY_DOMAINS.split(',')?.reduce((list, domain) => {
    const langDomainTempObject = {};
    const langDomainSplitted = domain?.split(':');
    langDomainTempObject[langDomainSplitted[0]?.toLowerCase()] =
      langDomainSplitted[1]?.toLowerCase();
    list.push(langDomainTempObject);

    return list;
  }, []);
}

/**
 * Gets the site url depending on the locale
 * @param {*} locale Locale to look up
 * @returns {string} Site url for the associated locale
 */
export function getDomainBasedOnLocale(locale) {
  const secondaryDomains = getSecondaryDomains();
  const selectedLanguageExists = secondaryDomains?.find((domain) =>
    Object.prototype.hasOwnProperty.call(domain, locale?.toLowerCase()),
  );

  if (selectedLanguageExists) {
    return `https://${selectedLanguageExists[locale]}`;
  }

  const NEXT_PUBLIC_PRIMARY_DOMAIN = process.env.NEXT_PUBLIC_PRIMARY_DOMAIN;
  return `https://${NEXT_PUBLIC_PRIMARY_DOMAIN}`;
}

/**
 * Gets the site url depending on the 2 letters country iso code
 * @param {*} countryIsoCode Country iso code to look up
 * @returns {string} Site url for the associated locale
 */
export function getDomainBasedOnCountryIsoCode(countryIsoCode) {
  const secondaryDomains = getSecondaryDomains();
  const selectedLanguageExists = secondaryDomains?.find((domain) => {
    const domainLocale = Object.keys(domain)[0]?.toLowerCase();

    if (domainLocale.endsWith(countryIsoCode?.toLowerCase())) return domainLocale;
  });

  if (selectedLanguageExists) {
    const locale = Object.keys(selectedLanguageExists)[0]?.toLowerCase();
    const domain = selectedLanguageExists[locale];
    return `https://${domain}`;
  }

  const NEXT_PUBLIC_PRIMARY_DOMAIN = process.env.NEXT_PUBLIC_PRIMARY_DOMAIN;
  return `https://${NEXT_PUBLIC_PRIMARY_DOMAIN}`;
}

/**
 * Gets the 2 character country iso code
 * @param {string} localeCode any string where the first 2 chars are the iso county code
 * @returns {string} lowercase 2 character country iso code
 */
export function getLangFromLocale(localeCode) {
  return localeCode.slice(0, 2).toLowerCase();
}

/**
 * Sleep function
 * @param {number} ms time in milliseconds to wait
 * @returns {Promise} returns after waiting ms time
 */
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Function to check if link is a pdf url
 * @param {string} url time in milliseconds to wait
 * @returns {boolean} returns true or false whether it is a pdf url or not
 */
export function isPDFLink(url) {
  return url.endsWith('.pdf');
}

/**
 * Function to prepare allowed file types for upload
 * @param {Array} fileTypesAllowed allowed file types from Contentful
 * @returns {object} returns an object with allowed file types
 */
export function getAllowedFileTypeObject(fileTypesAllowed) {
  const knownFileTypes = {
    Images: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif'] },
    Videos: { 'video/*': ['.mp4', '.mov', '.avi', '.mkv'] },
  };

  if (!Array.isArray(fileTypesAllowed) || !fileTypesAllowed.length > 0) return {};

  return fileTypesAllowed.reduce((obj, fileType) => {
    return { ...obj, ...(knownFileTypes[fileType] || {}) };
  }, {});
}

/**
 * Function to return fonts for a given brand and locale
 * @param {string} brand the brand to get the fonts for
 * @param {string} locale the locale to get the fonts for
 * @returns {string|Array} returns a string or an array of strings with the font links
 */
function getExternalFontLinks(brand, locale) {
  if (!brand || !locale) return null;
  if (externalFontMap[brand]) {
    if (externalFontMap[brand][locale] || externalFontMap[brand][locale] === null) {
      return externalFontMap[brand][locale];
    } else if (externalFontMap[brand]['default']) {
      return externalFontMap[brand]['default'];
    }
  }
  return null;
}

/**
 * Function to inject external fonts based on brand and language
 * @param {string} locale locale code to load fonts for
 * @returns {Array<JSX.Element>} array of link elements to be injected in the head
 */
export function fontLoader(locale) {
  const BRAND = process.env.BRAND;
  const fontLinks = [];
  const fontLink = (link, key = 'externalFont') => (
    <link key={key} rel={'stylesheet'} href={link} />
  );

  const fonts = getExternalFontLinks(BRAND, locale);

  if (fonts) {
    if (Array.isArray(fonts)) {
      fonts.forEach((link, index) => {
        fontLinks.push(fontLink(link, index));
      });
    } else {
      fontLinks.push(fontLink(fonts));
    }
  }

  return fontLinks;
}

/**
 * Function to validate the search credentials
 * @returns {boolean} whether the search credentials are valid
 */
function validateSiteSearchCredentials() {
  if (
    !process.env.NEXT_PUBLIC_ALGOLIA_SITE_SEARCH_APP ||
    !process.env.NEXT_PUBLIC_ALGOLIA_SITE_SEARCH_KEY
  ) {
    return false;
  }
  return true;
}

/**
 * Function to check if the locale has search enabled
 * @param {string} locale the current locale
 * @returns {boolean} whether the locale has search enabled
 */
export function localeHasSearch(locale) {
  if (!locale) return false;
  if (!process.env.NEXT_PUBLIC_LOCALES_WITH_SEARCH?.split(',').includes(locale.toLowerCase()))
    return false;
  return validateSiteSearchCredentials();
}
