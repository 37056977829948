/* eslint-disable max-len */
import enUS from '../en-us/index';

const zhCN = {
  common: {
    ctas: {
      close: '关闭',
      learnMore: '了解更多',
      learnMoreAbout: '了解更多关于',
      viewDetails: '查看详情',
      viewDetailsAbout: '查看有关详情',
      viewRecipe: '查看菜谱',
      contactUs: '联系我们',
      readMore: '阅读更多',
    },
    navigation: {
      regionSelectorLabel: '区域选择器',
      primaryNavLabel: '初级导航',
      whereToBuy: '购买途径',
      selectYourRegion: '选择你的地区',
      skipNavigation: '跳到主要内容',
    },
    faq: {
      faqs: '常见问题',
    },
    forms: {
      general: {
        termsLegend: '检查您是否同意',
        fieldErrorMsg: '此栏为空或无效，请在提交前正确填写',
        fileInvalidType: '提交的文件是一个无效的类型，请重试',
        fileTooLarge: '提交的文件太大，请上传一个较小的版本',
        fileTooSmall: '提交的文件是空的，请重试',
        tooManyFiles: '您输入了太多的文件，请再试一次',
        submitBtnText: '提交',
        submitErrorMsg: '请确保所有必要的字段都正确填写，并再次尝试。',
        networkErrorMsg: '向服务器发出的请求有误。请再试一次或通过电话或电子邮件联系我们。',
        successMsg: '您的信息已成功发送',
        selectFile: '选择文件',
        dragAndDrop: '在这里拖放你的文件，或点击选择',
        files: '文件',
        fileLimit: '请最多输入 ${1} 份文件',
        textTooLong: '此字段的最大限制值为 ${1} 个字符',
        textTooShort: '此字段要求最少有 ${1} 个字符',
      },
    },
    rate: {
      starLabel: '明星',
      starsLabel: '明星',
    },
    success: {
      message: '谢谢你的反馈意见!',
    },
    storeLocator: {
      tabbedHeader: {
        inStoreText: '当地零售商',
        buyOnlineText: '在线购买',
      },
      inputLocation: {
        label: '输入地点或邮政编码',
        submit: '提交搜索',
      },
      mapControls: {
        curbsideLabel: '可在路边取货',
        shopOnlineLabel: '可进行网上购物',
        zoomInLabel: '放大地图',
        zoomOutLabel: '放大地图',
      },
      filters: {
        filters: '过滤器',
        filterApplied: '应用的过滤器',
        filtersApplied: '应用的过滤器',
        clear: '清楚',
        clearFilters: '清除过滤器',
        openFilters: '开放式过滤器',
        closeFilters: '关闭过滤器',
        appliedFilters: '有${1}个过滤器被应用，按回车键可以关闭过滤器。',
        noFiltersApplied: '0 已应用的过滤器',
        noFilters: '没有选择过滤器',
        numFiltersApplied: '应用${1}个过滤器',
        numFilterApplied: '应用1个过滤器',
        filterStores: '过滤器商店',
      },
      map: {
        title: '提供${1}菜谱的地点',
      },
      infoList: {
        closeIcon: '关闭卡',
        curbsidePickup: '可在路边取货',
        directions: '路线图',
        website: '网站',
        buyOnline: '在线购买',
        facebook: '脸书',
        mi: '离你英里的地方',
        km: '离你公里的地方',
        loadMore: '载入更多',
        loadMoreStores: '载入更多商店',
      },
    },
    redirectModal: {
      headline: '你是从${1}来的吗?',
      subheadline: '访问你的区域网站，了解更多相关信息',
      redirectCta: '带我去那里',
      stayCta: '留在这里',
      closeModal: '留在这里',
    },
    confirmationModal: {
      headline: '你确定你要离开吗？',
      yes: '是',
      no: '没有',
    },
  },
  product: {
    hero: {
      review: '1条评论',
      reviews: '${1}条评论',
      askAQuestion: '提出问题',
      whereToBuy: '购买途径',
    },
    tabs: {
      whatsInside: "里面有什么",
      nutritionalInformation: '营养信息',
      header: {
        nowFresh: "为您的宠物提供完美平衡的营养",
        goSolutions: "来自值得信赖的合作伙伴的优质成分",
      }
    },
    table: {
      composition: '构成',
      additives: '每公斤添加剂',
      calorieContent: '卡路里含量',
      recommendedFor: '推荐给',
      ingredients: '营养成分',
      analyticalConstituents: '分析性成分',
      amount: '数量',
      guaranteedAnalysis: '保证分析',
      viewMore: '查看全部',
      viewLess: '查看更多',
      downloadNutrientProfile: '下载营养成分表',
    },
    review: {
      sectionTitle: '其他宠物家长的看法',
      writeAReview: '撰写评论',
      productStandouts: '突出的产品',
      noReviewTitle: '我们想听听你的宠物是怎么想的!',
      noReviewSubtitle: '目前该产品没有评论。',
      readMore: '阅读更多',
      readMoreFrom: '阅读更多来自${1}的文章',
      lessInformation: '更少的信息',
      portraitRespondent: '受访者画像',
      previousPage: '上一页',
      nextPage: '下一页',
      pageNumber: '第${1}页',
      sortReviews: '评论分类',
      sortLabel: '分类标准：',
      loadingMore: '正在加载中……',
      noMore: '没有更多评论了',
      loadMoreCta: '载入更多',
      aggregateSummary: '根据 ${1} 条评论',
      pagination: '显示${2}条评论中的${1}条评论。', 
      feedingSince: '喂养',
      petInfoTitle: '宠物信息',
      petName: '命名:',
      petAge: '年龄:',
      petBreed: '品种:',
      helpfulPrompt: '它是否有帮助？',
      helpfulPositive: '是',
      helpfulNegative: '没有',
      authorRecommends: '我推荐这款产品',
    viewLargeImage: '查看更大的图像',
      responseIntro: '来自 ${1} 的回复',
      showOriginal: '显示原始信息',
      translatedBy: '由 Google 翻译',
      translateWith: '使用 Google 翻译',
      filters: {
        filterReviews: '筛选评论',
        filterApplied: '使用了 ${1} 个筛选条件',
        filtersApplied: '使用了 ${1} 个筛选条件',
        result: '${1} 项结果',
        results: '${1} 项结果',
        filters: '过滤器',
        filterRecipes: '筛选食谱',
        noFilters: '没有选择过滤器',
        numFiltersApplied: '应用${1}个过滤器',
        numFilterApplied: '应用1个过滤器',
        clear: '清除',
        openFilters: '开放式过滤器',
        closeFilters: '关闭过滤器',
        clearFilters: '清除过滤器',
        appliedFilters: '有${1}个过滤器被应用，按回车键可以关闭过滤器。',
        noFiltersApplied: '0 已应用的过滤器',
        numStars: '${1} 星',
        numStar: '${1} 星',
        numReviews: '${1}条评论',
        numReview: '1条评论',
        rating: '评分',
      },
      sort: {
        oldest: '最久的',
        newest: '最新的',
        topRated: '评分最高的',
        lowRated: '评分最低的',
        helpfulness: '最有帮助的',
      },
    },
    feedingGuide: {
      eyebrow: '喂养指南',
      dogTitle: '为你的狗找到合适的数量',
      catTitle: '为您的猫咪找到合适的数量',
      moreInfo: '详细资料',
      bodyChartCTALabel: '身体得分表',
      petNeeds: '您的宠物每天大约需要${1}。',
      contactText: '通过电话或访问官网联系我们在中国的独家授权经销商 – 百加世',
    },
  },
  categoryLanding: {
    filters: {
      filterApplied: '${1} 应用的过滤器',
      filtersApplied: '${1} 应用的过滤器',
      result: '${1} 结果',
      results: '${1} 结果',
      filters: '过滤器',
      filterRecipes: '筛选菜谱',
      noFilters: '没有选择过滤器',
      numFiltersApplied: '应用${1}个过滤器',
      numFilterApplied: '应用1个过滤器',
      clear: '清除',
      openFilters: '开放式过滤器',
      closeFilters: '关闭过滤器',
      clearFilters: '清除过滤器',
      appliedFilters: '有${1}个过滤器被应用，按回车键可以关闭过滤器。',
      noFiltersApplied: '0 已应用的过滤器',
      filterCategories: {
        petType: '宠物的类型',
      }
    },
  },
  categoryLandingSyntheticTags: {
    dog: '犬类',
    cat: '猫咪'
  },
  blogPost: {
    authors: {
      authors: '作者',
      author: '作者',
    },
  },
  blogLanding: {
    featured: '特色',
    pagination: '显示${1} - ${2}的${3}篇文章',
  },
  countries: {
    af: '阿富汗',
    al: '阿尔巴尼亚',
    dz: '阿尔及利亚',
    as: '美国萨摩亚',
    ad: '安道尔',
    ao: '安哥拉',
    ai: '安圭拉',
    aq: '南极洲',
    ag: '安提瓜和巴布达',
    ar: '阿根廷',
    am: '亚美尼亚',
    aw: '阿鲁巴',
    au: '澳大利亚',
    at: '奥地利',
    az: '阿塞拜疆',
    bs: '巴哈马',
    bh: '巴林',
    bd: '孟加拉国',
    bb: '巴巴多斯',
    by: '白俄罗斯',
    be: '比利时',
    bz: '伯利兹',
    bj: '贝宁',
    bm: '百慕大',
    bt: '不丹',
    bo: '玻利维亚',
    ba: '波斯尼亚和黑塞哥维那（简称：波黑',
    bw: '博茨瓦纳',
    bv: '灌木岛',
    br: '巴西',
    io: '英属印度洋领地',
    bn: '文莱达鲁萨兰国',
    bg: '保加利亚',
    bf: '布基纳法索',
    bi: '布隆迪',
    kh: '柬埔寨',
    cm: '喀麦隆',
    ca: '加拿大',
    cv: '佛得角',
    ky: '开曼群岛',
    cf: '中非共和国',
    td: '乍得',
    cl: '智利',
    cn: '中国',
    cx: '圣诞岛',
    co: '哥伦比亚',
    km: '科摩罗',
    cg: '刚果',
    cd: '刚果民主共和国',
    ck: '库克群岛',
    cr: '哥斯达黎加',
    hr: '克罗地亚',
    cu: '古巴',
    cy: '塞浦路斯',
    cz: '捷克共和国',
    dk: '丹麦',
    dj: '吉布提',
    dm: '多米尼克',
    do: '多明尼加共和国',
    ec: '厄瓜多尔',
    eg: '埃及',
    sv: '萨尔瓦多',
    gq: '赤道几内亚',
    er: '厄立特里亚',
    ee: '爱沙尼亚',
    et: '埃塞俄比亚',
    fo: '法罗群岛',
    fj: '斐济',
    fi: '芬兰',
    fr: '法国',
    gf: '法属圭亚那',
    pf: '法国波利尼西亚',
    tf: '法国南部领土',
    ga: '加蓬',
    gm: '冈比亚',
    ge: '乔治亚州',
    de: '德国',
    gh: '加纳',
    gi: '直布罗陀',
    gr: '希腊',
    gl: '格陵兰',
    gd: '格林纳达',
    gp: '瓜德罗普',
    gu: '关岛',
    gt: '危地马拉',
    gg: '根西岛',
    gn: '几内亚',
    gw: '几内亚比绍',
    gy: '圭亚那',
    ht: '海地',
    hm: '听到岛屿和麦当劳群岛',
    va: '圣洁（梵蒂冈市州）',
    hn: '洪都拉斯',
    hk: '香港特别行政区, 中国',
    hu: '匈牙利',
    is: '冰岛',
    in: '印度',
    id: '印度尼西亚',
    ir: '伊朗伊斯兰共和国',
    iq: '伊拉克',
    ie: '爱尔兰',
    im: '伊斯勒人',
    il: '以色列',
    it: '意大利',
    jm: '牙买加',
    jp: '日本',
    je: '球衣',
    jo: '约旦',
    kz: '哈萨克斯坦',
    ke: '肯尼亚',
    ki: '基里巴斯',
    kp: '朝鲜民主主义人民共和国',
    kr: '大韩民国',
    kw: '科威特',
    kg: '吉尔吉斯斯坦',
    la: '老挝人民民主共和国',
    lv: '拉脱维亚',
    lb: '黎巴嫩',
    ls: '莱索托',
    lr: '利比里亚',
    ly: '利比亚阿拉伯Jamahiriya.',
    li: '列支敦士登',
    lt: '立陶宛',
    lu: '卢森堡',
    mo: '澳门',
    mk: '马其顿',
    mg: '马达加斯加',
    mw: '马拉维',
    my: '马来西亚',
    mv: '马尔代夫',
    ml: '马里',
    mt: '马耳他',
    mh: '马绍尔群岛',
    mq: '马提尼克岛',
    mr: '毛里塔尼亚',
    mu: '毛里求斯',
    mx: '墨西哥',
    fm: '密克罗尼西亚',
    md: '摩尔多瓦',
    mc: '摩纳哥',
    mn: '蒙古',
    ms: '蒙特塞拉特',
    ma: '摩洛哥',
    mz: '莫桑比克',
    mm: '缅甸',
    na: '纳米比亚',
    nr: '瑙鲁',
    np: '尼泊尔',
    nl: '荷兰',
    an: '荷属安的列斯',
    nc: '新喀里多尼亚',
    nz: '新西兰',
    ni: '尼加拉瓜',
    ne: '尼日尔',
    ng: '尼日利亚',
    nu: '纽厄斯',
    nf: '诺福克岛',
    mp: '北马里亚纳群岛',
    no: '挪威',
    om: '阿曼',
    pk: '巴基斯坦',
    pw: '帕劳',
    ps: '巴勒斯坦领土，被占领',
    pa: '巴拿马',
    pg: '巴布亚新几内亚',
    py: '巴拉圭',
    pe: '秘鲁',
    ph: '菲律宾',
    pl: '波兰',
    pt: '葡萄牙',
    pr: '波多黎各',
    qa: '卡塔尔',
    re: '团圆',
    ro: '罗马尼亚',
    ru: '俄罗斯联邦',
    rw: '卢旺达',
    sh: '圣海伦娜',
    kn: '圣基茨和尼维斯',
    lc: '圣卢西亚',
    pm: '圣皮埃尔和米利翁',
    vc: '圣文森特和格林纳丁斯',
    ws: '萨摩亚',
    sm: '圣马力诺',
    st: '圣多美和普林西比',
    sa: '沙特阿拉伯',
    sn: '塞内加尔',
    cs: '塞尔维亚和黑山',
    sc: '塞舌尔',
    sl: '塞拉利昂',
    sg: '新加坡',
    sk: '斯洛伐克',
    si: '斯洛文尼亚',
    sb: '所罗门群岛',
    so: '索马里',
    za: '南非',
    gs: '南乔治亚和南三明治群岛',
    es: '西班牙',
    lk: '斯里兰卡',
    sd: '苏丹',
    sr: '苏里南',
    sz: '斯威士兰',
    se: '瑞典',
    ch: '瑞士',
    sy: '阿拉伯叙利亚共和国',
    tw: '臺灣',
    tj: '塔吉克斯坦',
    tz: '坦桑尼亚，联合共和国',
    th: '泰国',
    tg: '多哥',
    to: '汤加',
    tt: '特立尼达和多巴哥',
    tn: '突尼斯',
    tr: '土耳其',
    tm: '土库曼斯坦',
    tc: '特克斯和凯科斯群岛',
    tv: '图瓦卢',
    ug: '乌干达',
    ua: '乌克兰',
    ae: '阿拉伯联合酋长国',
    gb: '英国',
    us: '美国',
    um: '美国未成年野外岛屿',
    uy: '乌拉圭',
    uz: '乌兹别克斯坦',
    vu: '瓦努阿图',
    ve: '委内瑞拉',
    vn: '越南',
    vg: '维尔京群岛，英国',
    vi: '维尔京群岛，美国',
    wf: '瓦利斯和福田',
    eh: '西撒哈拉',
    ye: '也门',
    zm: '赞比亚',
    zw: '津巴布韦',
    undef: '国际'
  },
};

export default Object.assign({}, enUS, zhCN);